export default ({ primary }, data, { $enhancedLinkSerializer }) => {
  const link = $enhancedLinkSerializer(primary.edito_cta_link);
  return {
    title: primary.edito_cta_title,
    description: primary.edito_cta_description,
    cta: link &&
      primary.edito_cta_link_label && {
        ...link,
        label: primary.edito_cta_link_label,
      },
    centerAligned: primary.edito_cta_center_aligned,
  };
};
